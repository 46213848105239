<template>
  <section class="studybox_bottom_area">
    <div class="studybox_bottom_area_left" v-if="back">
      <a href="#" @click.prevent="$router.push(back)"><img src="/image/common/bottom_arr_left.png" alt="하단 왼쪽 화살표"/></a>
    </div>
    <div class="studybox_bottom_area_right" v-if="next">
      <a href="#" @click.prevent="$router.push(next)"><img src="/image/common/bottom_arr_right.png" alt="하단 오른쪽 화살표"/></a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterNavigation',
  props: {
    back: {
      type: Object,
      required: false
    },
    next: {
      type: Object,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
